// 
// 	V A R I A B L E S
// 
$green = #00733f
$gray = #4c4d4f
$light-gray = #d9d9d9
$white = #fff


$primary-theme-color = $green



// 
// 	F O N T S
// 
@font-face
	font-family 'Gotham'
	font-path '/fonts/Gotham-Book'
	font-weight normal
	font-style normal
	
@font-face
	font-family 'Gotham'
	font-path '/fonts/Gotham-Bold'
	font-weight bold
	font-style normal



// 
// 	M I X I N S
// 
transition(props = all, time = .3s, animation = ease)
	transition props time animation
	
fill()
	position absolute
	top 0
	left 0
	height 100%
	width 100%
	
button($color = $primary-theme-color)
	margin-top .5em
	padding .5em 1.5em
	display inline-block
	border 3px solid $color
	color $color
	font-size 18px
	font-weight bold
	text-transform uppercase


// 
// 	S I T E
// 
body
	margin-top 130px
	font-family 'Gotham'
	
	.content-wrapper
		max-width 90%
		margin 0 auto
	
	img.ofi
		&.cover
			font-family 'object-fit: cover'
		&.contain 
			font-family 'object-fit: contain'


// 
// 	H E A D E R
// 
header
	box-sizing content-box
	padding-top 23px
	padding-bottom 24px
	width 100%
	position fixed
	top 0
	left 0
	background $white
	box-shadow 3px 0 3px 0 rgba(#000, .5)
	z-index 999
	.container-fluid
		max-width 90%
		margin 0 auto
	
	.navbar-brand
		img
			max-width 100%

	.main-menu
		align-items center
		border-right 2px solid $light-gray
	.nav-link
		margin-right 1.625em
		color $gray
		font-weight 700
		text-transform uppercase
		transition()
		&:hover
			color #000
			transition()
	.nav-link[href^="tel"]
		color $green
		border 3px solid $green
		transition()
		&:hover
			background-color $green
			color $white
			transition()
			
	.navbar-toggler
		padding 0
		border-radius 0
		&-line
			display block
			height 2px
			width 30px
			margin-bottom 8px
			background $black
			&:last-of-type
				margin-bottom 0
		&:hover
			.navbar-toggler-line
				background $primary-theme-color
	
	.translation
		padding-left 1.625em



// 
// 	F O O T E R
// 
footer
	padding 1em
	background $gray
	color $white
	.content-wrapper
		display flex
		justify-content space-between
	ul
		list-style-type none
		padding-left 0
		margin-bottom 0
		li
			display inline-block
			&.separator
				padding 0 .25em
				+below(700px)
					display none
			a
				color $white
				text-decoration underline
				&:hover
					color $white
					
	.privacy-policy-link
		color $white
		&:hover
			color $white
			font-weight bold
			text-decoration none



// 
// 	H O M E P A G E 
// 
.homepage
	.hero
		position relative
		height 745px
		display flex
		align-items center
		
		&-content
			position relative
			text-align center
			display block
			margin 0 auto
			width 850px
			max-width 100%
			color $white
			.message
				position absolute
				top 0
				left 0
				right 0
				margin 0 auto
				transform translateY(-40%)
			h1
				box-sizing border-box
				padding 35px 50px
				font-size responsive 28px 50px
				text-transform uppercase
				font-weight bold
			
			&::before,
			&::after
				position absolute
				content ''
				display block
				border 5px solid rgba($green, .5)
				height 25%
				width 25%
				
			&::before
				position absolute
				top 0
				left 0
				border-right 0
				border-bottom 0
				
			&::after
				position absolute
				right 0
				bottom 0
				border-left 0
				border-top 0
		
	.swiper-container
		position fixed
		top 130px
		left 0
		height 745px
		width 100%
		z-index -2
		&::after
			fill()
			content ''
			display block
			background rgba(#000, .7)
			z-index 2
		.swiper-slide
			background-size cover
			background-repeat no-repeat
		.slide1
			background-image url('/img/wiese-banner-1.jpg')
			background-size cover
			background-position center
			background-repeat no-repeat
		.slide2
			background-image url('/img/wiese-banner-2.jpg')
			background-size cover
			background-position center
			background-repeat no-repeat
		.slide3
			background-image url('/img/wiese-banner-3.jpg')
			background-size cover
			background-position center
			background-repeat no-repeat
		.slide4
			background-image url('/img/wiese-banner-4.jpg')
			background-size cover
			background-position center
			background-repeat no-repeat
			
	.swiper-pagination
		padding-bottom 1em
		&-bullets
			.swiper-pagination-bullet
				display inline-block
				margin 0 1.25em
				width 1em
				height 1em
				background-color $white
				transition()
				&-active
					background $white
					transition()
				

.split-row
	display flex
	max-width 100%
	.text-block
		padding 110px 135px 102px 175px
		display flex
		align-items center
		justify-content center
		width 50%
		background $white
		.content
			width 100%
		h2
			position relative
			padding-top 40px
			color $green
			font-size 18px
			text-transform uppercase
			font-weight bold
			margin-bottom 1em
			&::before
				content ''
				display block
				position absolute
				top 0
				left -50px
				width 150px
				height 75px
				border-left 3px solid $green
				border-top 3px solid $green
			
			
		.intro
			font-size responsive 20px 24px
			font-range 415px 768px
		
		p
			font-size 14px
			
		a
			button()
			
		
	.image-block
		width 50%
		background-attachment fixed
		background-size cover
	
	&.content-left
		.text-block
			order 1
		.image-block
			order 2
			background-position right center
			
	&:nth-child(even),
	&.content-right
		.text-block
			order 2
		.image-block
			order 1
			background-position left center
			
	
	&.about-block
		.image-block
			background-attachment fixed
			background-image url('/img/wiese-about.jpg')
			background-position 50% center
	&.products-block
		.image-block
			background-attachment fixed
			background-image url('/img/wiese-products.jpg')
			background-position 50% center
	&.dealers-block
		.image-block
			background-attachment fixed
			background-image url('/img/wiese-dealers.jpg')
			background-repeat no-repeat
			background-position 40% center
				

				



.contact
	background-image url('/img/wiese-contact.jpg');
	background-size cover
	background-position center top
	.text-block
		padding 110px 135px 0px 175px
		display flex
		align-items center
		justify-content center
		background transparent
		color $white
		order 1
		.content
			width 100%
		h2
			position relative
			padding-top 40px
			color $white
			font-size 18px
			text-transform uppercase
			font-weight bold
			margin-bottom 1em
			&::before
				content ''
				display block
				position absolute
				top 0
				left -50px
				width 150px
				height 75px
				border-left 3px solid $blue
				border-top 3px solid $blue
				border-color $white
	.form-area
		padding 100px
		max-width 1200px
		display flex
		justify-content center
		margin 0 auto
		width 100%
		order 2
		form
			width 100%
			height 100%
			display flex
			flex-wrap wrap
			flex-direction column
			.ff
				width 100%
				padding .5em
				border 1px solid $light-gray
				margin-bottom 1.75em
			.ff-email
				flex-basis 49%
			.ff-phone
				flex-basis 49%

			.ff-message
				flex 1 1 auto
				margin-bottom 0
			
			.submit-box
				margin-top 2em
				position relative
				height 44px
				width 100px
				.faker
					display flex
					align-items center
					justify-content center
					position absolute
					top 0
					left 0
					height 100%
					width 100%
					background $white
					color $primary-theme-color
					cursor pointer
					transition()
				&:hover
					.faker
						background $primary-theme-color
						color $white
						transition()	

			input[type=submit]
				position absolute
				top 0
				left 0
				heigth 100%
				width 100%
				padding 10px
				background-color $white
				color $primary-theme-color
				width 100px
				border none
				cursor pointer
				opacity 0
			input[type=submit]:hover
				background-color $primary-theme-color
				color $white

			div
				width 100%
				min-height 2em
				&.half
					width 50%
				&.tall
					flex 1 1 auto
					margin-bottom 0

			.field
				width 45%
			label
				display flex
				color $white
				font-size 18px
			textarea
				height 150px
						



// 
// 	R E S P O N S I V E
// 

+below(1600px)
	header
		.container-fluid
			max-width 100%


+below(1400px)
	header
		.nav-link
			font-size 14px


	.homepage
		.hero
			height 575px
			.swiper-container
				height 575px
				
	.split-row
		.text-block
			padding 50px 50px 50px 100px
			
		&.contact
			.form-area
				padding 100px 50px 100px 0
				

+below(1300px)
	header
		.nav-link
			margin .625em
		.translation
			padding-left .625em
				

+below(1199px)
	header
		padding 12px 0
		
	.split-row
		&.about-block
			.image-block
				background-size auto 100%
		
				
+between(991px, 1199px)
	header
		.navbar-collapse
			flex-direction column-reverse
			align-items flex-end
			.main-menu
				border-right 0
				.nav-link[href^="tel"]
					margin-right 0
			.translation
				margin-bottom .25em


+below(991px)
	body
		margin-top 85px

	header
		.main-menu
			margin-top 25px
			border-right 0
			.nav-link
				margin-right 0
				margin-bottom .25em
				&[href^="tel"]
					margin-top .75em
					padding .5em 1.5em
					
		.translation
			margin 1.25em 0
			padding-left 0
			display flex
			justify-content center
			
		
	.homepage
		.swiper-container
			top 85px
			.swiper-pagination-bullet
				margin 0 .5em
				height .75em
				width .75em

	.split-row
		display block
		.text-block,
		.image-block
			width 100%
			min-height 350px
		.text-block
			padding 100px
			
		&.contact
			.text-block
				padding-top 100px
			.form-area
				padding 0 50px 75px
				width 100%
				textarea
					min-height 250px
					
		&.dealers-block
			.image-block
				background-position 69% center
				

	footer
		padding 25px 0
		.content-wrapper
			flex-direction column
			align-items center
			.attribution
				margin-bottom 1em


+below(768px)
	.homepage
		.hero
			height 475px
			
			.hero-content
				.message
					top -50px
					transform initial
					
			.swiper-container
				height 475px
		
	.split-row
		.text-block
			padding 75px 75px 75px 100px
			h2
				&:before
					top 15px
					left -25px
					height 60px
			.button
				font-size 16px

	.contact
		.text-block
			padding 75px 75px 75px 100px
			h2
				&:before
					top 15px
					left -25px
					height 60px
		.form-area
			padding 20px
			.d-flex.justify-content-between
				flex-flow row wrap
			form
				.field
					width 100%

+below(700px)
	footer
		.attribution
			margin-bottom 1em
			li
				display block
				text-align center

+below(500px)
	body
		margin-top 60px
		
	header
		padding 0
	
	.homepage
		.swiper-container
			top  60px

		
	.split-row
		.text-block
			padding 35px 50px 50px
			
		&.contact
			.text-block
				padding 35px 50px 0
			.form-area
				padding 35px 50px 75px

	.contact
		.text-block
			padding 35px 50px 50px
				
	header
		.navbar-brand
			img
				height 35px
	
	main	
		.split-row
			.image-block
				background-attachment scroll


+below(420px)
	.split-row
		display block
		.image-block
			height 300px
			width 100%
			

+below(370px)
	.homepage
		.hero
			height 375px
			
			.hero-content
				h1
					font-size 18px
					
			.swiper-container
				height 375px
				